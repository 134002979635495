import { Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import Layout from './components/Layout';
import { useState } from 'react';
import SearchPostWordpress from '../SearchPostsWoredpress/SearchPostWordpress';
import { makePostRequest } from '../../utils/objectFormat';
import {
    capturarNumeroCapitulo,
    extraerDominiosServidores,
    extraerEnlaceFree,
    extraerEnlaceVip,
    extraerTamano,
    extraerTextoCapitulosDisponibles,
    extraerUrlsDeTextos,
    getLocalStorage,
    obtenerNombresDeHost,
    searchDataUser
} from '../../utils/functions';
import { getCategoriaWordpress, getpostsWordpress } from '../../utils/apiwp';
import images from '../../assets/images';
import { toast, Toaster } from 'react-hot-toast';
import { useStore } from '../../store/StoreProvider';

const tipoDePublicaion = [
    { value: '#NuevoPost', label: 'Post nuevo', selected: true },
    { value: '#NuevaSerie', label: 'Nueva Serie' },
    { value: '#PostCorregido', label: 'Post Corregido' },
    { value: '#NuevoCapitulo', label: 'Nuevo capitulo (Series)' },
    { value: '#NuevaTemporada', label: 'Nueva temporada (Series)' },
    { value: '#Resubida', label: 'Resubida' },
    { value: '#Comunicado', label: 'Comunicado' },
    { value: '#Colección', label: 'Colección' },
    { value: '#ContenidoExclusivo', label: 'Contenido exclusivo' }
];

const URL_PASOS_PARA_SER_VIP =
    'https://telegra.ph/Cu%C3%A1l-es-el-proceso-para-completar-mi-mebres%C3%ADa-VIP-11-16';

const StylesSelected = {
    width: '100%',
    marginBottom: '20px'
};

const StylesGridContainer = {
    backgroundColor: '#EFF5F5',
    marginTop: '10px',
    borderRadius: '5px',
    padding: '10px'
};

// GRUPO PRINCIPAL -1001527192006
// AnimeGalaxy.net (Canal) -1001500601482
// Pan •Noticias• -1001615539761
const arrayChatID = ['-1001527192006', '-1001500601482', '-1001615539761'];
// const arrayChatID = ['-1001637261112']; // prueba
// const chatID = '-1001637261112';
// const pruebaB = '-1001637261112';

const Telegram = () => {
    const { dataAEnviar, postWordpress, view, search } = useStore();
    const [tipoPost, setTipoPost] = useState(
        `${view === 4 ? '#NuevoPost' : ''}`
    );
    const [dataPost, setDataPost] = useState(postWordpress);
    const [arrayDataPost, setArrayDataPost] = useState([]);
    const [textoAdicional, setTextoAdicional] = useState('');
    const [nombresCategorias, setNombresCategorias] = useState([]);
    const [alias, setAlias] = useState('');
    const [pesoArchivo, setPesoArchivo] = useState('');
    // const [urlPortada, setUrlPortada] = useState(
    //     extraerUrlsDeTextos(dataPost?.content?.rendered)
    // );
    // const [enlaceVip, setEnlaceVip] = useState(
    //     extraerEnlaceVip(dataPost?.content?.rendered)
    // );
    // const [enlaceFree, setEnlaceFree] = useState(
    //     extraerEnlaceFree(dataPost?.content?.rendered)
    // );
    const urlPortada = extraerUrlsDeTextos(dataPost?.content?.rendered);
    const enlaceVip = extraerEnlaceVip(dataPost?.content?.rendered);
    const enlaceFree = extraerEnlaceFree(dataPost?.content?.rendered);
    const userData = getLocalStorage('user') || {};
    const [nombreServidores, setNombreServidores] = useState(
        obtenerNombresDeHost(dataAEnviar?.misServidores)
    );

    const epidosioDisponbible =
        extraerTextoCapitulosDisponibles(dataPost?.content?.rendered) ?? null;

    /**
     * VALIDAR EL PESO DEL ARCHIVO
     */
    const pesoArchivoString =
        search?.typeSearch === '1'
            ? `🎞️ Peso: ${dataAEnviar?.PesoArchivo}`
            : search?.typeSearch
            ? `🎞️ Peso: ${dataAEnviar?.PesoArchivo}`
            : '';

    /**
     * FUNCIONALIDAD PARA ENVIAR POST A TELEGRAM
     */

    const nn = '\n';

    const handlePost = async () => {
        let stringCategorias = `🎭`;
        for (let cat = 0; cat < nombresCategorias.length; cat++) {
            stringCategorias += `#${nombresCategorias[cat]?.name.replace(
                ' ',
                '_'
            )} `;
        }

        let misServidores = '☁️ ' + nombreServidores.join(', ');
        const textoCitado =
            epidosioDisponbible !== ''
                ? `
        <blockquote>🎥${epidosioDisponbible}</blockquote>`
                : '';
        const escapedMsg = `${tipoPost} ${nn} ${nn} 🎬 <b>${
            dataPost?.title?.rendered
        }</b> ${nn} ${nn} ${
            textoAdicional && `${textoAdicional} ${nn} ${nn}`
        } ${misServidores} ${nn} ${nn} ${stringCategorias} ${nn}${textoCitado}${nn}${nn} 👤 Uploader: ${alias} | ${pesoArchivo} `;

        for (let chatId = 0; chatId < arrayChatID.length; chatId++) {
            const response = await makePostRequest({
                photo: `${urlPortada[0]}`,
                caption: escapedMsg,
                parse_mode: 'html',
                chat_id: arrayChatID[chatId],
                reply_markup: {
                    inline_keyboard: [
                        [{ text: '🔥IR AL ENLACE🔥', url: dataPost?.link }],
                        [
                            {
                                text: '🍿 Ir a HDLATINO',
                                url: 'https://vip.hdlatino.us/'
                            },
                            {
                                text: `👤 Aportes: ${alias}`,
                                url: `https://vip.hdlatino.us/?s=${alias}`
                            }
                        ],
                        [
                            { text: '🏆 ENLACES  VIP', url: enlaceVip },
                            { text: '🤲 ENLACES FREE', url: enlaceFree }
                        ],
                        [
                            {
                                text: '¿PASOS PARA SER VIP?',
                                url: URL_PASOS_PARA_SER_VIP
                            }
                        ]
                    ]
                }
                // disable_notification: true
            });
            console.log('response', response);
        }
        toast.success('Enviado!');
    };

    const getGaterias = async () => {
        let categorias = [];
        let nombresCategorias = [];
        if (dataPost?.categories?.length > 3) {
            categorias = dataPost?.categories.slice(0, 3);
        } else {
            categorias = dataPost?.categories;
        }

        for (let i = 0; i < categorias?.length; i++) {
            const result = await getCategoriaWordpress(categorias[i]);
            nombresCategorias.push(result);
        }
        setNombresCategorias(nombresCategorias);
    };
    const getSize = () => {
        const size = extraerTamano(dataPost?.content?.rendered);
        setPesoArchivo(size);
    };
    const getServidores = () => {
        const servers = extraerDominiosServidores(dataPost?.content?.rendered);
        setNombreServidores(obtenerNombresDeHost(servers));
    };

    const getUser = async () => {
        const users = await searchDataUser();
        const user = users.data.filter(
            item => item.slug === userData.username.toLowerCase()
        );
        setAlias(user[0]?.name);
    };

    /**
     *
     * LISTAR INPUT CON LOS ULTIMOS 10 POST PUBLICADOS
     */

    const getPost = async () => {
        const { data, status } = await getpostsWordpress();

        if (status !== 200) {
            toast.error('Ocurrio un error');
            return;
        }

        setArrayDataPost(data);
    };

    useEffect(() => {
        getUser();
        getPost();
        setPesoArchivo(pesoArchivoString);
        // setDataPost(onePost);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getGaterias();
        getSize();
        getServidores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPost]);
    useEffect(() => {
        getPost();
        setDataPost(postWordpress);
    }, [postWordpress]);

    // useEffect(() => {
    //     if (Object.keys(onePost)?.length === 0) {
    //         setDataPost(dataPost);
    //     } else {
    //         setDataPost(onePost);
    //     }
    // }, [onePost]);

    return (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={8} sx={StylesGridContainer}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <img
                        src={images.Logo_Telegram2}
                        alt=""
                        style={{ width: '60px' }}
                    />
                    <h2 style={{ fontWeight: 'bold' }}>
                        Publicador de Post Telegram
                    </h2>
                </Box>
                <Box>
                    <TextField
                        defaultValue={tipoPost}
                        sx={StylesSelected}
                        size="small"
                        id="outlined-select-currency"
                        select
                        label="Tipo de publicación"
                        // value={tipoPost}
                        onChange={e => setTipoPost(e.target.value)}
                        // helperText="Please select your currency"
                    >
                        {tipoDePublicaion.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={StylesSelected}
                        size="small"
                        id="outlined-select-currency"
                        select
                        label="Ultimos posts"
                        // value={tipoPost}
                        onChange={e => setDataPost(e.target.value)}
                        // helperText="Please select your currency"
                    >
                        {arrayDataPost.map(post => (
                            <MenuItem key={post.id} value={post}>
                                {post?.title?.rendered}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Box sx={StylesSelected}>
                        <SearchPostWordpress setDataPost={setDataPost} />
                    </Box>

                    <TextField
                        size="small"
                        sx={StylesSelected}
                        id="outlined-select-currency"
                        label="Texto adicional"
                        value={textoAdicional}
                        onChange={e => setTextoAdicional(e.target.value)}
                        // helperText="Please select your currency"
                    />
                    <Stack direction={'row'} gap={2}>
                        <TextField
                            size="small"
                            sx={StylesSelected}
                            id="outlined-select-currency"
                            label="Peso"
                            value={pesoArchivo}
                            onChange={e => setPesoArchivo(e.target.value)}
                            // helperText="Please select your currency"
                        />
                        {extraerTextoCapitulosDisponibles(
                            dataPost?.content?.rendered
                        ) !== '' && (
                            <TextField
                                disabled
                                size="small"
                                sx={StylesSelected}
                                id="outlined-select-currency"
                                label="Capítulos"
                                value={extraerTextoCapitulosDisponibles(
                                    dataPost?.content?.rendered
                                )}
                            />
                        )}
                    </Stack>

                    <Button
                        sx={{
                            backgroundColor: '#ffc107',
                            color: '#000',
                            fontWeight: 'bold'
                        }}
                        onClick={() => handlePost()}
                        variant="contained"
                        // endIcon={<SendIcon />}
                        fullWidth={true}>
                        <div className="d-flex gap-2 align-items-center">
                            <img
                                src={images.Logo_Telegram3}
                                alt="telegram"
                                style={{ width: '26px' }}
                            />
                            Enviar mensaje a Telegram
                        </div>
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Layout
                    tipoPost={tipoPost}
                    dataPost={dataPost}
                    textoAdicional={textoAdicional}
                    urlPortada={urlPortada?.length > 0 ? urlPortada[0] : ''}
                    alias={`${alias} | ${pesoArchivo}`}
                    nombresCategorias={nombresCategorias}
                    numeroCapitulo={extraerTextoCapitulosDisponibles(
                        dataPost?.content?.rendered
                    )}
                />
            </Grid>
            <Toaster position="top-center" reverseOrder={false} />
        </Grid>
    );
};

export default Telegram;
