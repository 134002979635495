import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Button from '../components/button/Button';
import InfoMovie from '../components/infoMovie/InfoMovie';
import ListMovies from '../components/movie/ListMovies';
import Search from '../components/search/Search';
import { Spinner } from '../components/spinner/Spinner';
import StepsHeader from '../components/stepsHeader/StepsHeader';
import Step1 from '../pages/home/Step1';
import Step2 from '../pages/home/Step2';
import Step3 from '../pages/home/Step3';
import Step4 from '../pages/home/Step4';
import { useDispatch, useStore } from '../store/StoreProvider';
import { types } from '../store/storeReducer';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FiLogOut, FiMenu } from 'react-icons/fi';
import YoutubeSearch from '../components/youtubeSearch';
import FilmaFfinity from '../components/FilmaFfinity/FilmaFfinity';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarContent,
    SidebarFooter
} from 'react-pro-sidebar';

import Sidebar from '../components/Sidebar/Sidebar';
import Telegram from '../components/Telegram/Telegram';
import LayoutCrop from '../components/ImageCropper';

// Componente principal de la aplicación
const Layout = () => {
    // Recupera el estado de la aplicación y la función dispatch
    const { steps, view } = useStore();
    const dispatch = useDispatch();

    // Estado para mostrar u ocultar componentes
    const [show, setShow] = useState(false);

    // Función para gestionar los cambions entre vistas
    const handleView = val => {
        const sumar = view + val;
        dispatch({ type: types.VIEW, payload: sumar });
    };

    // Definición de las vistas a mostrar según el estado de la aplicación
    const VISTAS = {
        0: (
            <>
                <Search />
                <ListMovies />
            </>
        ),
        1: (
            <>
                <Search />
                <Step1 />
            </>
        ),
        2: <Step2 />,
        3: <Step3 />,
        4: <Step4 />
    };
    return (
        <>
            {/* Container principal de la aplicación */}
            <Row>
                {/* Columna izquierda: contendrá los componentes principales */}
                <Col xs={8}>
                    {/* Cabecera con pasos */}
                    <StepsHeader />
                    {/* Componentes según la vista activa */}
                    {VISTAS[view]}

                    {/* Botones de navegación */}
                    <div className="d-flex justify-content-end mt-2">
                        {view > 0 && (
                            <>
                                <Button
                                    onClick={() => handleView(-1)}
                                    props={{
                                        size: 'sm',
                                        variant: 'flat',
                                        type: 'xxl'
                                    }}>
                                    <div className="d-flex gap-2 align-items-center">
                                        <FaArrowLeft />
                                        Anterior
                                    </div>
                                </Button>
                                <Button
                                    // onClick={() => handleView(1)}
                                    props={{
                                        size: 'sm',
                                        variant: 'flat',
                                        type: 'submit',
                                        className: 'ms-2',
                                        form: `CreateForm${view}`
                                    }}>
                                    {view === 4 ? (
                                        'Enviar'
                                    ) : (
                                        <div className="d-flex gap-2 align-items-center">
                                            Siguiente
                                            <FaArrowRight />
                                        </div>
                                    )}
                                </Button>
                            </>
                        )}
                    </div>

                    {/* Componentes adicionales según la vista activa */}
                    {view === 1 && (
                        <Row>
                            <Col xs={12}>
                                <FilmaFfinity />
                            </Col>
                        </Row>
                    )}
                    {view === 2 && (
                        <Row>
                            <Col xs={12}>
                                <LayoutCrop />
                            </Col>
                        </Row>
                    )}
                </Col>

                {/* Columna derecha: contendrá información adicional */}
                <Col xs={4}>
                    <InfoMovie />
                    <YoutubeSearch />
                </Col>

                {/* Componentes adicionales según la vista activa */}
                {view === 4 && (
                    <Row>
                        <Col>
                            <Telegram />
                        </Col>
                    </Row>
                )}
            </Row>
        </>
    );
};

export default Layout;
