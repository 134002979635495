import {
    Box,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
    // Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import images from '../../assets/images';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { getWordpressPostSearch } from '../../utils/apiwp';
import Clipboard from '../Clipboard';
import InfoMovie from '../infoMovie/InfoMovie';
import ListMovies from '../movie/ListMovies';
import Search from '../search/Search';
import LayoutCrearPortada from './LayoutCrearPortada';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import { decodeEntity, validarFechaVencida } from '../../utils/functions';
import LogosProviders from '../LogosProviders/LogosProviders';
import { types } from '../../store/storeReducer';
import Button from '../button/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaArrowRight } from 'react-icons/fa';
import useFillTabs from '../FillTabs/useFillTabs';
import { getUsersPaste } from '../../utils/apipaste';
import { toast } from 'react-toastify';
import LayoutColecciones from '../colecciones/LayoutColecciones';
import LayoutSeasons from '../Seasons/LayoutSeasons';
import BasicModal from '../MyModal/BasicModal';
import FicharTabs from '../FicharPost/FicharTabs';
import { useSearchFicharPost } from '../FicharPost/store/store';

const CrearPortada = ({ content, setPostData, rootTypeSearch }) => {
    const [coincidencias, setCoincidencias] = useState([]);
    const [loadingCoincidencias, setLoadingCoincidencias] = useState(false);
    const [show, setShow] = useState(false);
    const { dataAEnviar, movie, pelisJustWhatchID, search } = useStore();
    const { urlpostergenerado } = dataAEnviar || {};
    const dispatch = useDispatch();
    const { key, handleSetKey } = useFillTabs();
    const { openModal, setOpenModal } = useSearchFicharPost();
    const allowedPaths = [
        '/',
        '/crear-pelicula',
        '/crear-serie',
        '/crear-coleccion'
    ];
    /**
     *
     * BUSCAR POST REPETIDOS
     */

    const handleGetPostRepeditos = async () => {
        setLoadingCoincidencias(true);
        const data = await getWordpressPostSearch(
            movie?.title?.replace(/[^\w\s]/gi, '')
        );
        setLoadingCoincidencias(false);
        setCoincidencias(data);
    };

    /**
     *
     * Avanzar a la siguiente pagina
     */
    const handleView = val => {
        dispatch({ type: types.VIEW, payload: 1 });
    };

    useEffect(() => {
        if (movie?.title) {
            handleGetPostRepeditos();
        }
    }, [movie]);

    useEffect(() => {
        const fetchUsers = async () => {
            const { data, status } = await getUsersPaste();

            if (status !== 'success') {
                console.log('error al pedir user', data);
                return;
            }
            const vencidos = data.filter(user =>
                validarFechaVencida(user?.expirationDateGD)
            );

            toast.error(`Usuarios VIP vencidos: ${vencidos.length}`, {
                className: 'background-t',
                position: 'top-right',
                autoClose: 10000
            });
        };
        fetchUsers();
        setOpenModal(!openModal);
    }, []);

    return (
        <>
            <Row>
                {/* <MyFirstStickyNotes /> */}
                <Col xs={8}>
                    <div className="d-flex justify-content-between gap-2 align-items-center fw-bold pb-2">
                        <div className="d-flex justify-content-start gap-2 align-items-center fw-bold pb-2">
                            <div>
                                <img
                                    src={images.crear_portada}
                                    alt=""
                                    width="45"
                                    height="45"
                                />
                            </div>
                            <div>
                                <h2>CREAR PORTADA</h2>
                            </div>
                        </div>
                        <div>
                            <BasicModal
                                buttonTitle={'Fichar posts'}
                                sxButton={sxButton}
                                styles={styleModal}>
                                <FicharTabs />
                            </BasicModal>
                        </div>
                    </div>
                    <Search
                        setShow={setShow}
                        show={show}
                        isPoster
                        rootTypeSearch={rootTypeSearch}
                    />
                    {search?.typeSearch === '2' ? (
                        <div id="colecciones" className="color_fondo">
                            <Tabs
                                defaultActiveKey="crear_colecciones"
                                id="dddd"
                                className="d-flex flex-wrap align-items-center justify-content-center color_fondo"
                                style={{
                                    backgroundColor: 'var(--color-fondo)'
                                }}>
                                <Tab
                                    eventKey="crear_colecciones"
                                    title="Colecciones"
                                    style={{
                                        border: '1px solid #dee2e6',
                                        borderTop: 0
                                    }}>
                                    <LayoutColecciones />
                                </Tab>
                                <Tab
                                    eventKey="crear__portada"
                                    title="Multimedia"
                                    className="color-fondo">
                                    <LayoutCrearPortada
                                        content={content}
                                        setPostData={setPostData}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    ) : null}

                    {search?.typeSearch === '0' &&
                    movie?.seasons?.length > 0 ? (
                        <div id="seasons" className="color_fondo">
                            <Tabs
                                defaultActiveKey="crear_seasons"
                                id="ddddd"
                                className="d-flex flex-wrap align-items-center justify-content-center color_fondo"
                                style={{
                                    backgroundColor: 'var(--color-fondo)'
                                }}>
                                <Tab
                                    eventKey="crear_seasons"
                                    title="Temporadas"
                                    style={{
                                        border: '1px solid #dee2e6',
                                        borderTop: 0
                                    }}>
                                    <LayoutSeasons />
                                </Tab>
                                <Tab
                                    eventKey="crear__portada"
                                    title="Multimedia"
                                    className="color-fondo">
                                    <LayoutCrearPortada
                                        content={content}
                                        setPostData={setPostData}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    ) : null}

                    {search?.typeSearch === '1' ? (
                        <LayoutCrearPortada
                            content={content}
                            setPostData={setPostData}
                        />
                    ) : null}

                    {/* <LayoutCrearPortada /> */}

                    {/* copiar al portapapeles */}
                    {/* <Clipboard text={urlpostergenerado} /> */}

                    {allowedPaths.includes(window.location.pathname) && (
                        <div className="d-flex justify-content-end mt-2">
                            <Button
                                onClick={() => {
                                    handleView(1);
                                    handleSetKey('crear_post');
                                }}
                                props={{
                                    size: 'sm',
                                    variant: 'flat',
                                    className: 'ms-2'
                                }}>
                                <div className="d-flex gap-2 align-items-center">
                                    Siguiente
                                    <FaArrowRight />
                                </div>
                            </Button>
                        </div>
                    )}
                </Col>
                <Col xs={4}>
                    <InfoMovie noForm={true} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Coincidencias</h3>
                    {loadingCoincidencias ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        <nav aria-label="main mailbox folders">
                            <List dense={true}>
                                {coincidencias?.length === 0
                                    ? 'No hay coincidencias'
                                    : coincidencias.map(post => (
                                          <ListItem disablePadding>
                                              <ListItemButton
                                                  onClick={() =>
                                                      window.open(post?.link)
                                                  }>
                                                  <ListItemIcon>
                                                      <MovieFilterIcon />
                                                  </ListItemIcon>
                                                  <ListItemText
                                                      primary={decodeEntity(
                                                          post?.title.rendered
                                                      )}
                                                  />
                                              </ListItemButton>
                                          </ListItem>
                                      ))}
                            </List>
                        </nav>
                    )}
                </Col>
            </Row>
            <LogosProviders providers={pelisJustWhatchID} />

            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                fullscreen={'lg-down'}
                // size="lg"
                // dialogClassName="modal-90w"
                // aria-labelledby="contained-modal-title-vcenter"
                aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Resultado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListMovies isPoster />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CrearPortada;

const sxButton = {
    sx: {
        backgroundColor: '#FFC206',
        color: 'black',
        fontWeight: 'bold',
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&:hover': {
            backgroundColor: '#d1a10a',
            color: '#fff',
            fontWeight: 'bold'
        }
    },
    variant: 'contained'
};

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 12,
    p: 1,
    overflowY: 'auto', // Agregar scroll vertical cuando sea necesario
    maxHeight: '90vh' // Limitar la altura para evitar que el modal sea demasiado grande
};
