import React, { useState } from 'react';
import {
    crearArrayInicialAcortadores,
    getLocalStorage,
    getNameValues,
    isValidURL
} from '../../../utils/functions';
import {
    acortadorCustom3,
    acortadorHdLatino
} from '../../../utils/acortadores';
import toast from 'react-hot-toast';

const useSortable = props => {
    const { handleAcortadorInputList, inputList } = props;
    const userData = getLocalStorage('user');
    const arrayItemsAcortadorInitial = crearArrayInicialAcortadores([
        ...(userData?.acortadores || [])
    ]);

    const [state, setState] = useState(arrayItemsAcortadorInitial);
    const [loading, setLoading] = useState(false);

    const handleSubmitSortableAll = async e => {
        e.preventDefault();
        e.stopPropagation();

        for (const inputObject of inputList) {
            if (isValidURL(inputObject.enlace) === false) {
                continue;
            }
            let linkAcortado = inputObject.enlace;

            for (const item of state) {
                console.log('item', item);
                const { id, name, key, host } = item;
                // const { [key]: value } = inputValues;
                const value = 1;

                if (value) {
                    setLoading(true);
                    try {
                        for (let i = 0; i < value; i++) {
                            if (key === 'ouo') {
                                linkAcortado = await acortadorCustom3(
                                    linkAcortado,
                                    host
                                );
                                linkAcortado = `https://ouo.io${
                                    new URL(linkAcortado).pathname
                                }`;
                                toast.success(linkAcortado);
                            } else if (key === 'hdlatino') {
                                linkAcortado = await acortadorHdLatino(
                                    linkAcortado
                                );
                                linkAcortado = linkAcortado.replace(
                                    /\r\n/g,
                                    ''
                                );
                                toast.success(linkAcortado);
                            } else {
                                linkAcortado = await acortadorCustom3(
                                    linkAcortado,
                                    host
                                );
                                linkAcortado = linkAcortado.replace(
                                    /\r\n/g,
                                    ''
                                );
                                toast.success(linkAcortado);
                            }
                        }
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        toast.error('Error:', key, error);
                    }
                }
            }
            handleAcortadorInputList(linkAcortado, inputObject?.id);
        }
        // dispatch({ type: types.VIEW, payload: sumar });
    };
    return { handleSubmitSortableAll, loading };
};

export default useSortable;
