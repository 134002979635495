import React, { useState } from 'react';
import {
    Box,
    Stack,
    TextField,
    TextareaAutosize as BaseTextareaAutosize,
    Typography,
    Alert,
    MenuItem
} from '@mui/material';
import { styled } from '@mui/system';
import { Form, Formik } from 'formik';
import DragAndDrop from '../../../components/DragArea/DragAndDrop';
import useEditPostView from '../hooks/useEditPostView';
import {
    capturarNumeroCapitulo,
    extraerCapitulos,
    extraerNumeroDesdeTexto,
    extraerSize,
    extraerTemporada,
    extraerTemporadasYCapítulos,
    extraerTextoCapitulosDisponibles,
    extraerTextoMasInfo,
    insertarCodigoHTML,
    modificarContenidoCapitulos,
    modificarContenidoMasinfo5,
    modificarContenidoTemporada,
    modificarSize
} from '../../../utils/functions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    customContent: {
        // Estilos personalizados para el contenido HTML
        padding: '8px',
        border: '2px solid #000',
        'border-radius': '8px',
        'background-color': '#fff',
        color: '#000',
        '& img': {
            display: 'inline-block',
            maxWidth: '20px',
            marginRight: '10px' // Espacio entre la imagen y el texto
        }
    }
}));

export const tipoDePublicaion = [
    {
        value: e => `Episodio ${e} Disponible`,
        label: 'Episodio # Disponible'
    },
    {
        value: e => `Episodio ${e} Disponible (Final)`,
        label: 'Episodio # Disponible (Final)'
    },
    {
        value: e => `Temporada ${e} Completa`,
        label: 'Temporada # Completa'
    },
    {
        value: e => `Temporadas
    ${e} Completas`,
        label: 'Temporadas # Completas'
    },
    { value: e => `${e}`, label: 'Texto Libre' }
];

const StylesGridContainer = {
    backgroundColor: '#EFF5F5',
    marginTop: '10px',
    borderRadius: '5px',
    padding: '10px'
};

const dataForm = [
    {
        title: 'Episodio',
        nameVar: 'capitulosDisponibles',
        nameVar2: 'valueCapitulosDisponibles',
        type: 'text',
        width: '20%',
        preview: e => `${e}`,
        editHtml: (html, e) => insertarCodigoHTML(html, e)
    },

    {
        title: 'Episodios',
        nameVar: 'episodios',
        type: 'text',
        width: '20%',
        preview: e => `Capítulos: ${e}`,
        editHtml: (html, e) => modificarContenidoCapitulos(html, e)
    },
    {
        title: 'Temporada',
        nameVar: 'temporada',
        type: 'text',
        width: '20%',
        preview: e => `Temporada: ${e}`,
        editHtml: (html, e) => modificarContenidoTemporada(html, e)
    },
    {
        title: 'Mas Info',
        nameVar: 'masInfo',
        type: 'textarea',
        width: '20%',
        preview: e => `Mas Info: ${e}`,
        editHtml: (html, e) => modificarContenidoMasinfo5(html, e)
    },
    {
        title: 'Peso del archivo',
        nameVar: 'peso',
        type: 'text',
        width: '20%',
        preview: e => `Peso: ${e}`,
        editHtml: (html, e) => modificarSize(html, e)
    }
];

const EditPostView = ({ content, setPostData }) => {
    const classes = useStyles();
    const { handleChangeSizePost } = useEditPostView();
    const [tipoPost, setTipoPost] = useState(0);

    const handleChangeData = (newString, index, setValues) => {
        let contentModificado;

        if (dataForm[index].nameVar === 'capitulosDisponibles') {
            const stringFianl = tipoDePublicaion[tipoPost].value(newString);
            setValues(prev => ({
                ...prev,
                valueCapitulosDisponibles: stringFianl,
                capitulosDisponibles: newString
            }));
            contentModificado = dataForm[index].editHtml(content, stringFianl);
        } else {
            setValues(prev => ({
                ...prev,
                [dataForm[index].nameVar]: newString
            }));
            contentModificado = dataForm[index].editHtml(content, newString);
        }

        setPostData(prev => ({
            ...prev,
            content: { rendered: contentModificado }
        }));
    };

    const handleChangeHTMLToEpisodio = e => {
        setTipoPost(() => e.target.value);
        const index = e.target.value;
        const stringLabel = tipoDePublicaion[index].label;
        if (stringLabel === 'Texto Libre') {
            const contentModificado = insertarCodigoHTML(content, stringLabel);
            setPostData(prev => ({
                ...prev,
                content: { rendered: contentModificado }
            }));
        }
    };
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                capitulosDisponibles: '',
                // capitulosDisponibles: extraerNumeroDesdeTexto(
                //     extraerTextoCapitulosDisponibles(content)
                // ),
                valueCapitulosDisponibles: '',
                peso: extraerSize(content),
                episodios: extraerTemporadasYCapítulos(content)?.capitulos,
                temporada: extraerTemporadasYCapítulos(content)?.temporadas,
                masInfo: extraerTextoMasInfo(content)
            }}>
            {({ values, handleChange, setValues }) => (
                <Box sx={StylesGridContainer}>
                    {/* <code>
                        {JSON.stringify(values.valueCapitulosDisponibles)}
                    </code> */}

                    <Form>
                        {dataForm.map((item, index) => (
                            <>
                                {item.nameVar === 'masInfo' ? (
                                    <>
                                        <Typography>Mas Info</Typography>

                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            gap={3}>
                                            <Textarea
                                                minRows={6}
                                                sx={{
                                                    marginBottom: '10px'
                                                }}
                                                label={item.title}
                                                aria-label="empty textarea"
                                                name={item.nameVar}
                                                value={values[item.nameVar]}
                                                onChange={e => {
                                                    // setValues({
                                                    //     ...values,
                                                    //     masInfo: e.target.value
                                                    // });
                                                    handleChangeData(
                                                        e.target.value,
                                                        index,
                                                        setValues
                                                    );
                                                }}
                                            />
                                            <div
                                                className={
                                                    classes.customContent
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: values.masInfo
                                                }}
                                            />
                                        </Stack>
                                    </>
                                ) : (
                                    <>
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            spacing={2}
                                            key={index}
                                            mb={2}>
                                            {item.nameVar ===
                                                'capitulosDisponibles' && (
                                                <TextField
                                                    defaultValue={tipoPost}
                                                    sx={{ width: '50%' }}
                                                    size="small"
                                                    id="outlined-select-currency"
                                                    select
                                                    label="Tipo de publicación"
                                                    onChange={e =>
                                                        handleChangeHTMLToEpisodio(
                                                            e
                                                        )
                                                    }
                                                    // helperText="Please select your currency"
                                                >
                                                    {tipoDePublicaion.map(
                                                        (option, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={index}>
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            )}

                                            <TextField
                                                size="small"
                                                sx={{ width: '50%' }}
                                                type={item.type}
                                                id="outlined-basic"
                                                label={item.title}
                                                name={item.nameVar}
                                                value={values[item.nameVar]}
                                                onChange={e => {
                                                    // item.nameVar ===
                                                    // 'capitulosDisponibles'
                                                    //     ? setValues({
                                                    //           ...values,
                                                    //           valueCapitulosDisponibles:
                                                    //               tipoDePublicaion[
                                                    //                   tipoPost
                                                    //               ].value(
                                                    //                   e.target
                                                    //                       .value
                                                    //               ),
                                                    //           capitulosDisponibles:
                                                    //               e.target.value
                                                    //       })
                                                    //     : handleChange(e);

                                                    handleChangeData(
                                                        e.target.value,
                                                        index,
                                                        setValues
                                                    );
                                                }}
                                            />
                                            {item.nameVar === 'peso' && (
                                                <Box sx={StyleForDragAndDrop}>
                                                    <DragAndDrop
                                                        handleChange={e => {
                                                            handleChangeSizePost(
                                                                e,
                                                                setValues,
                                                                values,
                                                                handleChangeData
                                                            );
                                                        }}
                                                        title="Arrastra tu archivo"
                                                    />
                                                </Box>
                                            )}
                                            <Alert
                                                sx={{ width: '80%' }}
                                                // variant="outlined"
                                                severity="success"
                                                icon={false}
                                                onChange={e =>
                                                    alert(e.target.value)
                                                }>
                                                {item.nameVar ===
                                                'capitulosDisponibles'
                                                    ? tipoDePublicaion[
                                                          tipoPost
                                                      ].value(
                                                          values[item.nameVar]
                                                      )
                                                    : item.preview(
                                                          values[item.nameVar]
                                                      )}
                                            </Alert>
                                        </Stack>
                                    </>
                                )}
                            </>
                        ))}
                    </Form>
                </Box>
            )}
        </Formik>
    );
};

export default EditPostView;

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75'
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025'
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 60%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 5px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
        theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
          theme.palette.mode === 'dark' ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const StyleForDragAndDrop = {
    width: '50%',
    height: '10%'
};
