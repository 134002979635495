import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import style from '../../assets/css/step4.module.css';
// import categorias from '../../categorias.json';
import Button from '../../components/button/Button';
import { FaUpload } from 'react-icons/fa';
import { Formik, Form as MyForm } from 'formik';
import { types } from '../../store/storeReducer';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { generarPost } from '../../utils/api';
import {
    createCategoriesAndTasgs,
    filterByQuality,
    getCategoriesWordpress
} from '../../utils/functions';
import Alert from '../../components/alert/Alert';
import metadata from '../../metadata.json';
import { FaFireAlt } from 'react-icons/fa';
import { getTraduction } from '../../utils/traductor';
import categoriasIngles from '../../utils/categoriasIngles.json';
import { putUpdatePostApartados } from '../../components/FicharPost/service/services';
import { useSearchFicharPost } from '../../components/FicharPost/store/store';
import { MenuItem, TextField } from '@mui/material';
import { tipoDePublicaion } from '../post/components/EditPostView';

const Step4 = () => {
    const { dataAEnviar, movie, metadataExtras } = useStore();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [loadingCategoria, setLoadingCategoria] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [statusPost, setStatusPost] = useState();
    const [statusCategoria, setStatusCategoria] = useState();
    const [categorias, setCategorias] = useState([]);
    const [linkPostCrerado, setLinkPostCrerado] = useState();
    const [tags, setTags] = useState([]);
    const [tagsYears, setTagsYears] = useState([]);
    const [tagsOtras, setTagsOtras] = useState([]);
    const [tagsCalidad, setTagsCalidad] = useState([]);
    const [tagsCasas, setTagsCasas] = useState([]);
    const [tagsResolucion, setTagsResolucion] = useState([]);
    const [dataCategorias, setDataCategorias] = useState();
    const [typeCategorias, setTypeCategorias] = useState();
    const [tipoPost, setTipoPost] = useState(0);
    const [tipoPublicacionValue, setTipoPublicacion] = useState('');
    const { post } = useSearchFicharPost();

    console.log('tipoPost', tipoPost);
    console.log('tipoPublicacionValue', tipoPublicacionValue);

    const { tituloPaste } = dataAEnviar;

    const step4handleSubmit = async values => {
        setLoadingSubmit(true);
        setStatusPost('');
        dispatch({
            type: types.DATAAENVIAR,
            payload: { ...movie, ...values, parts: null }
        });

        try {
            const template = await generarPost({
                ...movie,
                ...dataAEnviar,
                ...values,
                parts: null
            });

            await putUpdatePostApartados({
                id: post.id,
                apar_completed: true
            });
            console.log('template', template);
            if (template.status === 200) {
                dispatch({
                    type: types.DATAAENVIAR,
                    payload: { ...movie, ...values, parts: null }
                });
                dispatch({
                    type: types.POST_WORDPRESS,
                    payload: template.data.resul
                });

                setLinkPostCrerado(template.data.resul.link);
                setStatusPost('success');
                setLoadingSubmit(false);
            }
        } catch (error) {
            setStatusPost('error');
            setLoadingSubmit(false);
        }
    };
    useEffect(() => {
        const asyncFunc = async categorias => {
            // const stringToArray = movie?.generos.join().trim();
            // const stringTraducido = await getTraduction(stringToArray);
            // const arrayGenerosTraducidos = stringTraducido.text.split(',');
            // movie.generos = arrayGenerosTraducidos;
            let misCalidades = [];
            let misCasas = [];
            let misResoluciones = [];
            let arrayClean = [];
            const cat = await getCategoriesWordpress('categories');
            console.log('cat', cat);
            const tag = await getCategoriesWordpress('tags');
            const tagYear = tag?.filter(item => Number(item.name));
            // const tagOtra = tag?.filter(item => !Number(item.name));
            tag?.forEach(item => {
                return (
                    filterByQuality(
                        item.name.toUpperCase(),
                        metadata,
                        'Calidad'
                    )[0]?.label.toUpperCase() === item.name.toUpperCase() &&
                    misCalidades.push(item)
                );
            });
            tag?.forEach(item => {
                return (
                    filterByQuality(
                        item.name.toUpperCase(),
                        metadataExtras,
                        'Extra'
                    )[0]?.label.toUpperCase() === item.name.toUpperCase() &&
                    misCasas.push(item)
                );
            });
            tag?.forEach(item => {
                return (
                    filterByQuality(
                        item.name.toUpperCase(),
                        metadata,
                        'Resolucion'
                    )[0]?.label.toUpperCase() === item.name.toUpperCase() &&
                    misResoluciones.push(item)
                );
            });
            tag?.forEach(item => {
                return (
                    filterByQuality(
                        item.name.toUpperCase(),
                        metadata,
                        'Extra'
                    )[0]?.label.toUpperCase() === item.name &&
                    misResoluciones.push(item)
                );
            });
            arrayClean = [
                ...misCalidades,
                ...misResoluciones,
                ...misCasas,
                ...tagYear
            ];
            let variations = tag.filter(
                res =>
                    !arrayClean.some(id_prueba =>
                        id_prueba.name.includes(res.name)
                    )
            );

            setTagsOtras(variations);
            setTagsYears(tagYear);

            //convertimos el nombre de la cateoria en mayusculas
            setCategorias(cat);
            setTags(tag);
            setTagsCalidad(misCalidades);
            setTagsCasas(misCasas);
            setTagsResolucion(misResoluciones);
            setLoading(false);
        };
        asyncFunc();
    }, []);

    const resolucion = {
        i1080p: '1080p',
        i720p: '720p',
        i480p: '480p',
        i360p: '360p',
        i1440p: '1440p',
        i2160p: '2160p',
        ifull1080p: '1080p',
        i4k: '4K'
    };
    const arayTitleIdioma = [
        'Latino',
        'Ingles',
        'Subtitulado',
        'Castellano',
        'Portugues',
        'Italiano',
        'Japones',
        'Chino',
        'Coreano',
        'Ruso',
        'Holandes',
        'Frances',
        'Aleman'
    ];
    let titleIdioma = arayTitleIdioma?.filter(res =>
        dataAEnviar?.FormatoAudio?.some(id_prueba =>
            id_prueba?.label.toUpperCase()?.includes(res?.toUpperCase())
        )
    );

    const handledataCategorias = e => {
        setDataCategorias(e.target.value);
    };
    const handleSubmitCategorias = async e => {
        e.preventDefault();
        setLoadingCategoria(true);
        setStatusCategoria('');
        try {
            const res = await createCategoriesAndTasgs(
                { name: dataCategorias },
                typeCategorias || 'categories'
            );
            setStatusCategoria('success');
            setLoadingCategoria(false);
        } catch (error) {
            setStatusCategoria('error');
            setLoadingCategoria(false);
        }
    };

    /**
     * Validar las categorias preseleccionadas
     */
    const misCat = [...categorias, ...categoriasIngles];
    const misCategoriasPrevias = misCat
        ?.filter(i => {
            const generos = Array.isArray(movie?.generos)
                ? movie?.generos
                : [movie?.generos];
            return generos
                .map(n => n?.toUpperCase()?.trim())
                ?.includes(i?.name?.toUpperCase());
        })
        ?.map(e => `${e?.id}`);
    /**
     * Validar las etiquetas preseleccionadas
     */
    const resolucionASeleccionar = resolucion[dataAEnviar?.Resolucion];

    /**
     * Agrupar etiquetas seleccionadas para agregar al formulario y marcar los cheks
     */

    const misTagsArrayPrevias = [
        movie?.year,
        resolucionASeleccionar?.toUpperCase(),
        dataAEnviar?.Calidad?.toUpperCase(),
        dataAEnviar?.Extra?.toUpperCase(),
        dataAEnviar?.Plus?.toUpperCase()
    ];

    const misEtiquetassPrevias = tags
        ?.filter(i =>
            misTagsArrayPrevias
                .map(n => n?.toUpperCase()?.trim())
                .includes(i?.name?.toUpperCase())
        )
        .map(e => `${e?.id}`);

    // preseleccionar etiqueta estreno
    movie?.year >= Number('2023') && misEtiquetassPrevias.push('52');

    // preseleccionar etiqueta pelicula o serie
    if (misCategoriasPrevias.includes('18')) {
        misEtiquetassPrevias.push('72');
    } else {
        movie?.typeSearch === '1'
            ? misEtiquetassPrevias.push('50')
            : misEtiquetassPrevias.push('54');
    }

    return (
        <div className={style.container__step4}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        miscategoriasfull: misCategoriasPrevias || [],
                        mistags: misEtiquetassPrevias || [],
                        mititle:
                            tituloPaste.slice(0, -4) ||
                            `${movie.title || movie.original_name} (${
                                movie.release_date?.substring(0, 4) ||
                                movie.first_air_date.slice(0, -6)
                            }) [${
                                resolucion[dataAEnviar.Resolucion] || '480p'
                            }] {NTFX} ${
                                dataAEnviar.Calidad
                            } [${titleIdioma.join('-')}]`,
                        publicarOBorrador: 'pending',
                        tipoPublicacion: tipoPublicacionValue
                    }}
                    onSubmit={values => step4handleSubmit(values)}>
                    {({
                        values,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <MyForm>
                            <Row>
                                <Col sm={12} className="text-center mb-2">
                                    <h6>Categorias</h6>
                                </Col>
                                {categorias?.map(categoria => (
                                    <Col
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={categoria.id}>
                                        <Form.Check
                                            type="checkbox"
                                            id={`step4categoria${categoria.id}`}
                                            label={categoria.name.toUpperCase()}
                                            size="sm"
                                            name="miscategoriasfull"
                                            value={categoria.id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.miscategoriasfull?.includes(
                                                `${categoria.id}`
                                            )}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Row
                                className="text-center"
                                style={{ borderTop: 'solid 10px #fff' }}>
                                <Col sm={12} className="mt-3">
                                    <h6>Etiquetas</h6>
                                </Col>

                                {/* {tags?.map(tag => (
                                    <Col sm={6} md={4} lg={3} key={tag.id}>
                                        <Form.Check
                                            type="checkbox"
                                            id={`step4tag${tag.id}`}
                                            label={tag.name.toUpperCase()}
                                            size="sm"
                                            name="mistags"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={tag.id}
                                        />
                                    </Col>
                                ))} */}
                            </Row>
                            <Row
                                style={{ borderTop: 'thick green' }}
                                className="pt-3 pb-3">
                                <Col sm={12}>
                                    <h6>Year</h6>
                                </Col>
                                {tagsYears?.map(tag => (
                                    <Col sm={6} md={4} lg={3} key={tag.id}>
                                        {Number(tag.name) ? (
                                            <Form.Check
                                                type="checkbox"
                                                id={`step4tag${tag.id}`}
                                                label={tag.name.toUpperCase()}
                                                size="sm"
                                                name="mistags"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={tag.id}
                                                checked={values.mistags?.includes(
                                                    `${tag.id}`
                                                )}
                                            />
                                        ) : null}
                                    </Col>
                                ))}
                            </Row>
                            <Row
                                style={{ borderTop: 'solid 10px #fff' }}
                                className="pt-3 pb-3">
                                <Col sm={12}>
                                    <h6>Calidad</h6>
                                </Col>
                                {tagsCalidad?.map(tag => (
                                    <Col sm={6} md={4} lg={3} key={tag.id}>
                                        <Form.Check
                                            type="checkbox"
                                            id={`step4tag${tag.id}`}
                                            label={tag.name.toUpperCase()}
                                            size="sm"
                                            name="mistags"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={tag.id}
                                            checked={values.mistags?.includes(
                                                `${tag.id}`
                                            )}
                                        />
                                    </Col>
                                ))}
                            </Row>

                            <Row
                                style={{ borderTop: 'solid 10px #fff' }}
                                className="pt-3 pb-3">
                                <Col sm={12}>
                                    <h6>Resolución</h6>
                                </Col>
                                {tagsResolucion?.map(tag => (
                                    <Col sm={6} md={4} lg={3} key={tag.id}>
                                        <Form.Check
                                            type="checkbox"
                                            id={`step4tag${tag.id}`}
                                            label={tag.name.toUpperCase()}
                                            size="sm"
                                            name="mistags"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={tag.id}
                                            checked={values.mistags?.includes(
                                                `${tag.id}`
                                            )}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Row
                                style={{ borderTop: 'solid 10px #fff' }}
                                className="pt-3 pb-3">
                                <Col sm={12}>
                                    <h6>Casas</h6>
                                </Col>
                                {tagsCasas?.map(tag => (
                                    <Col sm={6} md={4} lg={3} key={tag.id}>
                                        <Form.Check
                                            type="checkbox"
                                            id={`step4tag${tag.id}`}
                                            label={tag.name.toUpperCase()}
                                            size="sm"
                                            name="mistags"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={tag.id}
                                            checked={values.mistags?.includes(
                                                `${tag.id}`
                                            )}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Row
                                style={{ borderTop: 'solid 10px #fff' }}
                                className="pt-3 pb-3">
                                <Col sm={12}>
                                    <h6>Otras</h6>
                                </Col>
                                {tagsOtras?.map(tag => (
                                    <Col sm={6} md={4} lg={3} key={tag.id}>
                                        {!Number(tag.name) ? (
                                            <Form.Check
                                                type="checkbox"
                                                id={`step4tag${tag.id}`}
                                                label={tag.name.toUpperCase()}
                                                size="sm"
                                                name="mistags"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={tag.id}
                                                checked={values.mistags?.includes(
                                                    `${tag.id}`
                                                )}
                                            />
                                        ) : null}
                                    </Col>
                                ))}
                            </Row>
                            <Row
                                className="mt-3 pt-2"
                                style={{ borderTop: 'solid 10px #fff' }}>
                                <Col>
                                    <TextField
                                        defaultValue={tipoPost}
                                        sx={{ width: '50%' }}
                                        size="small"
                                        id="outlined-select-currency"
                                        select
                                        label="Tipo de publicación"
                                        onChange={e =>
                                            setTipoPost(() => e.target.value)
                                        }
                                        // helperText="Please select your currency"
                                    >
                                        {tipoDePublicaion.map(
                                            (option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={index}>
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                    <TextField
                                        size="small"
                                        sx={{ width: '50%' }}
                                        type="text"
                                        id="outlined-basic"
                                        label="Episodio"
                                        // name={nameVar}
                                        // value={values[item.nameVar]}
                                        onChange={e => {
                                            setTipoPublicacion(
                                                tipoDePublicaion[
                                                    tipoPost
                                                ].value(e.target.value)
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row
                                className="mt-3 pt-2"
                                style={{ borderTop: 'solid 10px #fff' }}>
                                {/* <Col sm={12} className="mt-3">
                                    <h6>Etiquetas</h6>
                                </Col> */}
                                <Col>
                                    <label htmlFor="step3enlacevip">
                                        Titulo del post
                                    </label>
                                </Col>
                                <Col sm={6} className="my-auto">
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Titulo del post"
                                            size="sm"
                                            id="step3enlacevip"
                                            name="mititle"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mititle}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                            Estado
                                        </InputGroup.Text>
                                        <Dropdown>
                                            <Form.Select
                                                defaultValue="pending"
                                                aria-label="Default select example"
                                                size="sm"
                                                name="publicarOBorrador"
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option value="pending">
                                                    Revision
                                                </option>
                                                <option value="draft">
                                                    Borrador
                                                </option>
                                                <option value="publish">
                                                    Publicar
                                                </option>
                                            </Form.Select>
                                        </Dropdown>
                                    </InputGroup>
                                </Col>
                                <Col sm={12}>
                                    <div className="d-grid gap-3">
                                        <Button
                                            props={{
                                                size: 'sm',
                                                variant: 'flat',
                                                type: 'xxl'
                                            }}>
                                            <div className="d-flex gap-2 align-items-center justify-content-center">
                                                <div className="w-0">
                                                    {loadingSubmit ? (
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <FaUpload />
                                                    )}
                                                </div>
                                                Generar Post
                                            </div>
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    {statusPost === 'success' && (
                                        <Alert
                                            success
                                            linkPostCrerado={linkPostCrerado}
                                        />
                                    )}
                                    {statusPost === 'error' && <Alert error />}
                                </Col>
                            </Row>
                            <Row className="pt-3">
                                <Col sm={3}>
                                    <label htmlFor="step3enlacevip">
                                        Crear Categorias o Etiqueta
                                    </label>
                                </Col>
                                <Col sm={3} className="my-auto">
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Crear Categorias o Etiqueta"
                                            size="sm"
                                            id="step3enlacevip"
                                            name="dataCategorias"
                                            onChange={handledataCategorias}
                                            value={dataCategorias}
                                            // onBlur={handleBlur}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">
                                            Tipo
                                        </InputGroup.Text>
                                        <Dropdown>
                                            <Form.Select
                                                defaultValue="categories"
                                                aria-label="Default select example"
                                                size="sm"
                                                name="typeCategorias"
                                                onChange={e =>
                                                    setTypeCategorias(
                                                        e.target.value
                                                    )
                                                }>
                                                <option
                                                    value="none"
                                                    selected
                                                    disabled
                                                    hidden>
                                                    Escoger opcion
                                                </option>
                                                {/* <option disabled value="">
                                                    Escoger opcion
                                                </option> */}
                                                <option
                                                    value="categories"
                                                    selected="selected">
                                                    Categoria
                                                </option>
                                                <option value="tags">
                                                    Etiqueta
                                                </option>
                                            </Form.Select>
                                        </Dropdown>
                                    </InputGroup>
                                </Col>
                                <Col sm={3} className="position-relative">
                                    <div className="position-absolute top-0 start-0">
                                        <Button
                                            props={{
                                                size: 'sm',
                                                variant: 'flat',
                                                type: 'button',
                                                disabled:
                                                    loadingCategoria && true
                                            }}
                                            onClick={e =>
                                                handleSubmitCategorias(e)
                                            }>
                                            <div className="d-flex gap-2 align-items-center">
                                                {loadingCategoria ? (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <FaFireAlt />
                                                )}
                                                Crear
                                            </div>
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    <Col>
                                        {statusCategoria === 'success' && (
                                            <Alert
                                                success
                                                text={
                                                    typeCategorias === 'tags'
                                                        ? 'Etiqueta creada'
                                                        : 'Categoria creada'
                                                }
                                            />
                                        )}
                                        {statusCategoria === 'error' && (
                                            <Alert
                                                error
                                                text={
                                                    typeCategorias === tags
                                                        ? 'Error al crear Etiqueta'
                                                        : 'Error al crear Categoria'
                                                }
                                            />
                                        )}
                                    </Col>
                                </Col>
                            </Row>
                        </MyForm>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default Step4;
